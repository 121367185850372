import {BaseModel} from './base.model';

export class DoctorModel extends BaseModel {
    //
    // properties
    //
    name = '';
    professionalLicense = '';
    medicalSpeciality = '';
    specialityProfessionalLicense = '';
    cellPhone = '';
    phone = '';
    nameOfWorkplace = '';
    address = '';
    city = '';
    state = '';
    country = '';
    zipCode = '';
    prescription = '';
    status = 'pending';


    initFromObject(data) {
        super.initFromObject(data);
        return this;
    }
}
