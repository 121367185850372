import {BaseQuote} from "./base-quote.model";
import {Order} from "./order.model";
import _ from 'lodash';

export class Permit extends BaseQuote {
  // status
  static STATUS_PENDING = 'pending';
  static STATUS_IN_PROGRESS = 'in-progress';
  static STATUS_READY = 'ready';
  static STATUS_UNREAD = 'unread';
  static STATUS_READ = 'read';
  static STATUS_UNDER_REVIEW = 'under-review';
  static STATUS_INCOMPLETE_PAPERWORK = 'incomplete-paperwork';
  static STATUS_PENDING_ORIGINAL_DOCUMENTS = 'pending-original-documents';
  static STATUS_PENDING_SUPPLIER_INFORMATION = 'pending-supplier-information';
  static STATUS_PENDING_PRODUCT_INFORMATION = 'pending-product-information';
  static STATUS_APPOINTMENT_PENDING = 'appointment-pending';
  static STATUS_SCHEDULED_APPOINTMENT = 'scheduled-appointment';
  static STATUS_APPLICATION_SUBMITTED = 'application-submitted';
  static STATUS_UNDER_EVALUATION = 'under-evaluation';
  static STATUS_COFEPRIS_COLLECTION = 'cofepris-collection';
  static STATUS_AUTHORIZED = 'authorized';
  static STATUS_PREVENTION = 'prevention';
  static STATUS_DENIED = 'denied';
  static STATUS_CANCELED = 'canceled';
  static STATUS_DELIVERED_TO_CUSTOMER = 'delivered-to-customer';
  static STATUS_WAITING_DOC = 'waiting-doc';
  static STATUS_SENT = 'sent';
  static PROOF_OF_RELATION = 'fileProofOfRelation';
  static PATIENT_IDENTIFICATION = 'fileOfPatientIdentification';

  static STORAGE_PATH = 'permits';

  static KEY_OFFICIAL_ID = 'fileOfficialId';
  static KEY_OFFICIAL_ID_REVERSE = 'fileOfficialIdReverse';
  static KEY_PROOF_ADDRESS = 'fileProofAddress';
  static KEY_PRESCRIPTION = 'filePrescription';
  static KEY_BIRTH_CERT = 'fileBirthCert';
  static KEY_TAX_NUMBER = 'fileTaxNumber';
  static KEY_OFFICIAL_ID_WITNESS1 = 'fileOfficialIdWit1';
  static KEY_OFFICIAL_ID_WITNESS1_REVERSE = 'fileOfficialIdWit1Reverse';
  static KEY_OFFICIAL_ID_WITNESS2 = 'fileOfficialIdWit2';
  static KEY_OFFICIAL_ID_WITNESS2_REVERSE = 'fileOfficialIdWit2Reverse';
  static KEY_PRIVACY_NOTICE = 'filePrivacyNotice';
  static KEY_RFC = 'fileTaxId';
  static KEY_RFC_REVERSE = 'fileTaxIdReverse';

  static KEY_POWER_ATTORNEY = 'filePowerAttorney';

  static KEY_TAX_NUMBER_LEGAL = 'fileTaxNumberLegal';
  static KEY_OFFICIAL_ID_LEGAL = 'fileOfficialIdLegal';
  static KEY_OFFICIAL_ID_LEGAL_REVERSE = 'fileOfficialIdLegalReverse';
  static KEY_POWER_ATTORNEY_LEGAL = 'filePowerAttorneyLegal';

  static KEY_TAX_NUMBER_SANITARY = 'fileTaxNumberSanitary';
  static KEY_OFFICIAL_ID_SANITARY = 'fileOfficialIdSanitary';
  static KEY_OFFICIAL_ID_SANITARY_REVERSE = 'fileOfficialIdSanitaryReverse';
  static KEY_NOTICE_SANITARY = 'fileNoticeSanitary';
  static KEY_LICENSE_SANITARY = 'fileLicenseSanitary';

  static KEY_IMPORT_PERMIT = 'filePermit';
  static KEY_PAYMENT_PROOF = 'filePaymentProof';
  static KEY_DISCLAIMER = 'fileDisclaimer';
  static KEY_LETTER_REASON = 'fileLetterReason';
  static KEY_AUTHORIZATION_FORM = 'fileAuthorizationForm';
  static KEY_AWB_FORMAT = 'fileAwbFormat';
  static KEY_COMMERCIAL_INVOICE = 'fileCommercialInvoice';
  static KEY_PROOF_FEE = 'fileProofFee';

  static KEY_ID_NUMBER_CAREGIVER = 'fileIdNumberCaregiver';
  static KEY_REAL_ID_CAREGIVER_FRONT = 'fileRealIdCaregiverFront';
  static KEY_REAL_ID_CAREGIVER_BACK = 'fileRealIdCaregiverBack';
  static KEY_CAREGIVER_BIRTH_CERTIFICATE = 'fileCaregiverBirthCertificate';
  static KEY_FEDERAL_TAXPAYERS_REGISTRY = 'fileFederalTaxpayersRegister';

  // upload-by-admin-repository-doc
  static KEY_LISFARMA_APDP_EMPTY_DOC = 'lisfarmaApdpEmptyDoc';
  static KEY_LISFARMA_APDP_RESPONSIBLE_DOC = 'lisfarmaApdpResponsibleDoc';
  static KEY_LISFARMA_CITIMGS_RESPONSABLE_EMPTY_DOC = 'lisfarmaCitimgsResponsibleEmptyDoc';
  static KEY_LISFARMA_CITIMGS_EMPTY_DOC = 'lisfarmaCitimgsEmptyDoc';
  static KEY_LISFARMA_RESPONSIBLE_REASON_EMPTY_DOC = 'lisfarmaResponsibleReasonEmptyDoc';
  static KEY_LISFARMA_REASON_EMPTY_DOC = 'lisfarmaReasonEmptyDoc';
  static KEY_LISFARMA_POWER_OF_ATTORNEY_EMPTY_DOC = 'lisfarmaPowerOfAttorneyEmptyDoc';
  static KEY_LISFARMA_COMPLETE_AUTHORIZATION_EMPTY_DOC = 'lisfarmaCompleteAuthorizationEmptyDoc';

  // upload-by-admin-repository-pdf
  static KEY_LISFARMA_APDP_EMPTY_PDF = 'lisfarmaApdpEmptyPdf';
  static KEY_LISFARMA_APDP_RESPONSIBLE_PDF = 'lisfarmaApdpResponsiblePdf';
  static KEY_LISFARMA_CITIMGS_RESPONSABLE_EMPTY_PDF = 'lisfarmaCitimgsResponsibleEmptyPdf';
  static KEY_LISFARMA_CITIMGS_EMPTY_PDF = 'lisfarmaCitimgsEmptyPdf';
  static KEY_LISFARMA_RESPONSIBLE_REASON_EMPTY_PDF = 'lisfarmaResponsibleReasonEmptyPdf';
  static KEY_LISFARMA_REASON_EMPTY_PDF = 'lisfarmaReasonEmptyPdf';
  static KEY_LISFARMA_POWER_OF_ATTORNEY_EMPTY_PDF = 'lisfarmaPowerOfAttorneyEmptyPdf';
  static KEY_LISFARMA_COMPLETE_AUTHORIZATION_EMPTY_PDF = 'lisfarmaCompleteAuthorizationEmptyPdf';

  // upload-by-patient-repository-doc
  static KEY_LISFARMA_APDP_EMPTY_PATIENT_DOC = 'lisfarmaApdpEmptyPatientDoc';
  static KEY_LISFARMA_APDP_RESPONSIBLE_PATIENT_DOC = 'lisfarmaApdpResponsiblePatientDoc';
  static KEY_LISFARMA_CITIMGS_RESPONSABLE_EMPTY_PATIENT_DOC = 'lisfarmaCitimgsResponsibleEmptyPatientDoc';
  static KEY_LISFARMA_CITIMGS_EMPTY_PATIENT_DOC = 'lisfarmaCitimgsEmptyPatientDoc';
  static KEY_LISFARMA_RESPONSIBLE_REASON_EMPTY_PATIENT_DOC = 'lisfarmaResponsibleReasonEmptyPatientDoc';
  static KEY_LISFARMA_REASON_EMPTY_PATIENT_DOC = 'lisfarmaReasonEmptyPatientDoc';
  static KEY_LISFARMA_POWER_OF_ATTORNEY_EMPTY_PATIENT_DOC = 'lisfarmaPowerOfAttorneyEmptyPatientDoc';
  static KEY_LISFARMA_COMPLETE_AUTHORIZATION_EMPTY_PATIENT_DOC = 'lisfarmaCompleteAuthorizationEmptyPatientDoc';

  // upload-by-patient-repository-pdf
  static KEY_LISFARMA_APDP_EMPTY_PATIENT_PDF = 'lisfarmaApdpEmptyPatientPdf';
  static KEY_LISFARMA_APDP_RESPONSIBLE_PATIENT_PDF = 'lisfarmaApdpResponsiblePatientPdf';
  static KEY_LISFARMA_CITIMGS_RESPONSABLE_EMPTY_PATIENT_PDF = 'lisfarmaCitimgsResponsibleEmptyPatientPdf';
  static KEY_LISFARMA_CITIMGS_EMPTY_PATIENT_PDF = 'lisfarmaCitimgsEmptyPatientPdf';
  static KEY_LISFARMA_RESPONSIBLE_REASON_EMPTY_PATIENT_PDF = 'lisfarmaResponsibleReasonEmptyPatientPdf';
  static KEY_LISFARMA_REASON_EMPTY_PATIENT_PDF = 'lisfarmaReasonEmptyPatientPdf';
  static KEY_LISFARMA_POWER_OF_ATTORNEY_EMPTY_PATIENT_PDF = 'lisfarmaPowerOfAttorneyEmptyPatientPdf';
  static KEY_LISFARMA_COMPLETE_AUTHORIZATION_EMPTY_PATIENT_PDF = 'lisfarmaCompleteAuthorizationEmptyPatientPdf';


    // upload-revised-field-by-admin-repository-doc
    static KEY_IS_REVISED_LISFARMA_APDP_EMPTY_DOC = 'isRevisedlisfarmaApdpEmptyDoc';
    static KEY_IS_REVISED_LISFARMA_APDP_RESPONSIBLE_DOC = 'isRevisedlisfarmaApdpResponsibleDoc';
    static KEY_IS_REVISED_LISFARMA_CITIMGS_RESPONSABLE_EMPTY_DOC = 'isRevisedlisfarmaCitimgsResponsibleEmptyDoc';
    static KEY_IS_REVISED_LISFARMA_CITIMGS_EMPTY_DOC = 'isRevisedlisfarmaCitimgsEmptyDoc';
    static KEY_IS_REVISED_LISFARMA_RESPONSIBLE_REASON_EMPTY_DOC = 'isRevisedlisfarmaResponsibleReasonEmptyDoc';
    static KEY_IS_REVISED_LISFARMA_REASON_EMPTY_DOC = 'isRevisedlisfarmaReasonEmptyDoc';
    static KEY_IS_REVISED_LISFARMA_POWER_OF_ATTORNEY_EMPTY_DOC = 'isRevisedlisfarmaPowerOfAttorneyEmptyDoc';
    static KEY_IS_REVISED_LISFARMA_COMPLETE_AUTHORIZATION_EMPTY_DOC = 'isRevisedlisfarmaCompleteAuthorizationEmptyDoc';

  //
  // properties
  //
  currentStatus = Permit.STATUS_UNREAD;
  fromLanding = false;

  professionalLicenseLegal = '';
  professionalLicenseSanitary = '';

  //
  // properties
  //
  orderId = '';

  // documents
  filePrescription = null;
  fileBirthCert = null;
  fileOfficialId = null;
  fileOfficialIdReverse = null;
  fileProofAddress = null;
  fileOfficialIdWit1 = null;
  fileOfficialIdWit1Reverse = null;
  fileOfficialIdWit2 = null;
  fileOfficialIdWit2Reverse = null;

  fileOfficialIdLegal = null;
  filePowerAttorneyLegal = null;

  fileLicenseSanitary = null;
  fileOfficialIdSanitary = null;
  fileNoticeSanitary = null;

  filePrivacyNotice = null;
  filePowerAttorney = null;
  filePermit = null;
  fileDisclaimer = null;
  fileLetterReason = null;
  fileAuthorizationForm = null;
  fileAwbFormat = null;
  fileCommercialInvoice = null;
  fileProofFee = null;

  // logical
  order = null;

  initFromObject(data) {
    super.initFromObject(data);

    // order
    if (_.isObject(this.order)) {
      this.order = new Order().initFromObject(this.order);
    } else {
      this.orderId = this.order;
    }

    return this;
  }

  entryDate() {
    return this.statuses.find(s => s.status === Permit.STATUS_IN_PROGRESS)?.createdAt;
  }

  completeDate() {
    return this.statuses.find(s => s.status === Permit.STATUS_SENT)?.createdAt;
  }
}
