import { User } from "../models/user.model";
import { ApiService } from "../services";
import store from "../store/configureStore";
import { setUserSelected } from "../actions/actions";
import i18n from "../i18n";

export class UserHelper {
  static instance = null;

  static getInstance() {
    if (!this.instance) {
      this.instance = new UserHelper();
    }

    return this.instance;
  }

  static typeNameCustomer(type) {
    // eslint-disable-next-line default-case
    switch (type) {
      case User.TYPE_USER_PATIENT:
        return i18n.t("patient");

      case User.TYPE_USER_PHYSICIAN:
        return i18n.t("physician");

      case User.TYPE_USER_INSTITUTION:
        return i18n.t("institution");
    }

    return "Patient";
  }

  static areaName(area) {
    switch (area) {
      case User.TYPE_SALES:
        return "Customer Service";

      case User.TYPE_OP:
        return "Operations";

      case User.TYPE_PU:
        return "Purchasing";

      case User.TYPE_REG:
        return "Regulations";
    }

    return "";
  }

  static typeName(user) {
    return UserHelper.typeNameCustomer(user?.type);
  }

  static sectorName(sector) {
    switch (sector) {
      case User.SECTOR_PUBLIC:
        return "Public";

      case User.SECTOR_PRIVATE:
        return "Private";
    }

    return "";
  }

  static areaList() {
    return [
      { value: User.TYPE_SALES, name: "Customer Service" },
      { value: User.TYPE_OP, name: "Operations Area" },
      { value: User.TYPE_PU, name: "Purchasing Area" },
      { value: User.TYPE_REG, name: "Regulations Area" },
    ];
  }

  async fetchUserFromUrl(search, saveToStore = true) {
    const uiReducer = store.getState().UiReducer;

    if (uiReducer.userSelected) {
      return Promise.resolve(uiReducer.userSelected);
    }

    const params = new URLSearchParams(search);
    const userId = params.get("user");

    const user = await ApiService.getUserById(userId);

    if (saveToStore) {
      // save user to reduce
      store.dispatch(setUserSelected(user));
    }

    return Promise.resolve(user);
  }

  static nameReadable(user) {
    // console.log("user", user);
    const { type, fullName, username, email, companyName } = user;
    if (type === User.TYPE_ADMIN) {
      return "Admin";
    }
    if (type === User.TYPE_USER_INSTITUTION) {
      return companyName;
    }
    return username;
  }

  static getUserBaseData = (user, login = false) => {
    const baseData = {};
    baseData.address = user.address;
    baseData.zipCode = user.zipCode;
    baseData.town = user.town;
    baseData.state = user.state;
    baseData.country = user.country;
    baseData.phone = user.phone;
    baseData.cellPhone = user.cellPhone;

    if (login ) {
      baseData.id = user?.id
      baseData.type = user?.type
      baseData.token = user?.apiToken;
      baseData.apiToken = user?.apiToken;
    }

    baseData.firstName = user.firstName;
    baseData.paternalName = user.paternalName;
    baseData.maternalName = user.maternalName;
    baseData.birthday = user.birthday;
    baseData.taxId = user.taxId;
    baseData.personalId = user.personalId;
    baseData.isForeigner = user.isForeigner;
    baseData.email = user?.email;
    baseData.password = user?.password;
    baseData.reasonCreate =
      user.reasonCreate?.length && Array.isArray(user.reasonCreate)
        ? user.reasonCreate?.join(",")
        : "";
    baseData.reasonCreateOther = user.reasonCreateOther;

    if (user?.type === User.TYPE_USER_PATIENT) {
      if (user.hasCaregiver) {
        baseData.caregiverName = user.caregiverName;
        baseData.caregiverDirection = user.caregiverDirection;
        baseData.caregiverPhone = user.caregiverPhone;
        baseData.caregiverEmail = user.caregiverEmail;
      }
    } else if (user?.type === User.TYPE_USER_TUTOR) {
      if (user.relation) {
        baseData.relation = user?.relation
      }
    } else if (user?.type === User.TYPE_USER_PHYSICIAN) {
      baseData.institution = user.institution;
      baseData.professionalLicense = user.professionalLicense;
      baseData.specialty = user.specialty;
      baseData.professionalSpecialtyLicense = user.professionalSpecialtyLicense;
    } else if (user?.type === User.TYPE_USER_INSTITUTION) {
      baseData.companyName = user.companyName;
      baseData.sector = user.sector;
      baseData.nameResponsible = user.nameResponsible;
      baseData.positionResponsible = user.positionResponsible;
      baseData.taxpayerId = user.taxpayerId;
      baseData.businessName = user.businessName;
      baseData.isMainInstitution = user.isMainInstitution;
      baseData.mainInstitution = user.mainInstitution;
    }
    return baseData;
  };

  static isBaseDataFilled(user) {
    let enabled = false;

    if (user?.type === User.TYPE_USER_PATIENT || user?.type === User.TYPE_USER_TUTOR) {
      enabled =
        user.firstName &&
        user.paternalName &&
        user.birthday &&
        user.address &&
        user.zipCode &&
        // user.town &&
        // user.state &&
        user.cellPhone;
      // user.personalId;

      if (!user.isForeigner) {
        enabled = user.taxId && user.personalId;
      }

      //   if (enabled && user.taxId) {
      //     enabled = Utils.isValidRFC(user.taxId, true);
      //   }

      if (user.hasCaregiver) {
        enabled =
          enabled &&
          user.caregiverName &&
          user.caregiverDirection &&
          user.caregiverPhone &&
          user.caregiverEmail;
      }
    } else if (
      user?.type === User.TYPE_USER_PHYSICIAN ||
      user.type === User.TYPE_USER_COMPANY
    ) {
      enabled =
        user.institution &&
        user.address &&
        user.firstName &&
        user.zipCode &&
        // user.town &&
        // user.state &&
        user.professionalLicense &&
        user.professionalSpecialtyLicense &&
        user.cellPhone;
    } else if (user?.type === User.TYPE_USER_INSTITUTION) {
      enabled =
        user.companyName &&
        user.sector &&
        user.nameResponsible &&
        user.positionResponsible &&
        user.address &&
        user.zipCode &&
        // user.town &&
        // user.state &&
        user.cellPhone &&
        user.taxpayerId;
    }

    return enabled;
  }
}

export const isRoleAdmin = (user) =>
  user.type === User.TYPE_ADMIN || user.type === User.TYPE_REPOSITORY || user.type === User.TYPE_SUB_ADMIN_1;

export const hasCaregiver = (user) => {
  return !!user?.caregiverName;
};

export const userTypeParsed = (type) => {
  if (
    type?.toLowerCase() === User.TYPE_USER_PATIENT ||
    type?.toLowerCase() === User.TYPE_USER_PHYSICIAN ||
    type?.toLowerCase() === User.TYPE_USER_INSTITUTION
  ) {
    return type?.toLowerCase();
  }

  return undefined;
};

export const repoManagerChild = (user) =>
  user.type === User.TYPE_REPOSITORY && user.parent;

export const getFormateDate = (birthDay) => {
  const dateObj = new Date(birthDay);
  // get the month in this format of 04, the same for months
  const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
  const day = ("0" + dateObj.getDate()).slice(-2);
  const year = dateObj.getFullYear();

  const shortDate = `${year}-${month}-${day}`;
  return shortDate;
};
