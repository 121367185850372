import {BaseModel} from './base.model';
import {config} from "../helpers/config";
import _ from "lodash";

export class User extends BaseModel {
    static TYPE_USER_PATIENT = 'patient';
    static TYPE_USER_PHYSICIAN = 'physician';
    static TYPE_USER_INSTITUTION = 'institution';
    static TYPE_EMPLOYEE = 'employee';
    static TYPE_ADMIN = 'admin';
    static TYPE_SUB_ADMIN_1 = 'subadmin1';
    static TYPE_USER_TUTOR = 'tutor';

    // todo: replace with roles
    static TYPE_OTHER = 'other';
    static TYPE_REPOSITORY = 'repo-manager';

    static REASON_IMPORT_PATIENTS = 'reason-import-patients';
    static REASON_IMPORT_CLINICAL = 'reason-import-clinical';
    static REASON_PRODUCT_EXPORT = 'reason-product-export';
    static REASON_IMPORT_OTHER = 'reason-import-other';

    static STORAGE_PATH = 'users';
    static STORAGE_PATH_IMAGE = 'users/images';

    static SECTOR_PUBLIC = 'sector-public';
    static SECTOR_PRIVATE = 'sector-private';

    static TYPE_SALES = 'sales';
    static TYPE_OP = 'op';
    static TYPE_PU = 'pu';
    static TYPE_REG = 'reg';
    static TYPE_LOG = 'log';

    static STATUS_ENABLED = 'enabled';
    static STATUS_DISABLED = 'disabled';
    static STATUS_PENDING = 'pending';


    static KEY_OFFICIAL_ID = 'fileOfficialId';
    static KEY_PROOF_ADDRESS = 'fileProofAddress';
    static KEY_TAX_NUMBER = 'fileTaxNumber';
    

    //
    // properties
    //
    username = '';
    email = '';
    type = '';
    area = '';

    reasonCreate = '';
    reasonCreateOther = '';

    // base
    address = '';
    zipCode = '';
    town = '';
    state = '';
    country = config.defaultCountry;
    phone = '';
    cellPhone = '';
    photo = '';
    shippingAddress = '';

    status = User.STATUS_PENDING;

    // patient
    firstName = '';
    paternalName = '';
    maternalName = '';
    birthday = '';
    taxId = '';
    personalId = '';
    medicationRequired = '';

    // caregiver
    caregiverName = '';
    caregiverDirection = '';
    caregiverPhone = '';
    caregiverEmail = '';

    //
    // physician
    //
    institution = '';
    specialty = '';
    professionalLicense = '';
    professionalSpecialtyLicense = '';
    patientIds = [];

    //
    // institution
    //
    companyName = '';
    sector = '';
    nameResponsible = '';
    positionResponsible = '';
    taxpayerId = '';
    businessName = '';

    //
    // employee
    //
    employeeNumber = '';

    emailConfirmed = false;
    phoneConfirmed = false;

    //
    // sales
    //
    salesRepresentative = '';
    salesEmail = '';

    //
    // logical
    //
    confirmEmailSent = false;

    photoUrl = '';
    patients = [];

    initFromObject(data) {
        super.initFromObject(data);

        //
        // patients
        //
        const patients = [];
        const patientIds = [];

        for (const p of this.patients) {
            if (_.isObject(p)) {
                patients.push(new User().initFromObject(p));
            } else {
                patientIds.push(p);
            }
        }

        this.patientIds = patientIds;
        this.patients = patients;

        return this;
    }

    profileObject() {
        const name = {
            firstName: this.firstName,
            paternalName: this.paternalName,
            maternalName: this.maternalName,
        };

        const address = {
            address: this.address,
            zipCode: this.zipCode,
            town: this.town,
            state: this.state,
            country: this.country,
            phone: this.phone,
            cellPhone: this.cellPhone,
        };

        let profile = {};

        if (this.type === User.TYPE_USER_PATIENT) {
            profile = {
                ...name,
                ...address,
                email: this.email,
                birthday: this.birthday,
                taxId: this.taxId,
                personalId: this.personalId,

                caregiverName: this.caregiverName,
                caregiverDirection: this.caregiverDirection,
                caregiverPhone: this.caregiverPhone,
                caregiverEmail: this.caregiverEmail,
                caregiverRelation: this.caregiverRelation,
            };
        } else if (this.type === User.TYPE_USER_PHYSICIAN) {
            profile = {
                ...name,
                ...address,
                email: this.email,
                institution: this.institution,
                professionalLicense: this.professionalLicense,
                institutionId: this.institutionId,
                specialty: this.specialty,
                professionalSpecialtyLicense: this.professionalSpecialtyLicense,

                reasonCreate: this.reasonCreate,
                reasonCreateOther: this.reasonCreateOther,
            };
        } else if (this.type === User.TYPE_USER_INSTITUTION) {
            profile = {
                companyName: this.companyName,
                sector: this.sector,
                nameResponsible: this.nameResponsible,
                positionResponsible: this.positionResponsible,
                mainInstitution: this.mainInstitution,
                isMainInstitution: this.isMainInstitution,

                ...address,
                email: this.email,
                taxpayerId: this.taxpayerId,
                businessName: this.businessName,

                reasonCreate: this.reasonCreate,
                reasonCreateOther: this.reasonCreateOther,
            };
        } else if (this.type === User.TYPE_EMPLOYEE) {
            profile = {
                ...name,
                area: this.area,
                employeeNumber: this.employeeNumber,
                ...address,
                email: this.email,
                taxId: this.taxId,
                personalId: this.personalId,
            };
        }

        profile.photo = this.photo;

        return profile;
    }

    getFilePath(fileName) {
        if (!fileName) {
            return '';
        }

        return `${User.STORAGE_PATH}/${this.id}/${fileName}`;
    }
}
